import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import Checkbox from '@mui/joy/Checkbox';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalDashboardOperations from "./modals/modalDashboardOperations";
import ModalDashboardNominals from "./modals/modalDashboardNominals";
import _, { set } from "lodash";

//SERVICES  
import MiddleOperationService from "../../services/middleOperationService";


function MesaDashboardReport() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [listEscoOperations, setListEscoOperations] = useState([])
    const [listModalOperations, setListModalOperations] = useState([])
    const [totalPesos, setTotalPesos] = useState(0)
    const [totalMep, setTotalMep] = useState(0)
    const [totalCable, setTotalCable] = useState(0)
    const [nominal, setNominal] = useState(0)
    const [modalOperationOpen, setModalOperationOpen] = useState(false)
    const [modalNominalOpen, setModalNominalOpen] = useState(false)
    const [searchDate, setsearchDate] = useState(new Date().toISOString().slice(0,10))
    // let defaultDate = new Date().toISOString().slice(0,10);
    
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad(searchDate)
        }
    }, [false]);

    const doLoad = async (date) => {
        try {  
            console.log("NICO: ", date)
            setLoading(true)
            // ESCO OPERATIONS 
            const responseEsco = await MiddleOperationService.getMiddleOperationsConsolidatedDate(date)
            // const responseEsco = await MiddleOperationService.getMiddleOperationsConsolidated()
            setListEscoOperations(responseEsco.data)
            setTotalPesos( sumOperations(responseEsco.data.pesos, "P") )
            setTotalCable( sumOperations(responseEsco.data.cable, "D") )
            setTotalMep( sumOperations(responseEsco.data.mep, "M") )
            sumOperationsNominal(responseEsco.data)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }

    const doViewOperations = async (currency) => {
        if(currency === 'P'){
            setListModalOperations(listEscoOperations.pesos)
        }else if(currency === 'DM'){
            setListModalOperations(listEscoOperations.mep)
        }else if(currency === 'DC'){
            setListModalOperations(listEscoOperations.cable)
        }
        setModalOperationOpen(true)
    }

    const sumOperations = function(arr, currency){
        let total = 0
        arr.forEach( function(item, key) {
            // total = total + parseFloat(item.price) * item.qty
            total = total + parseFloat(item.gross_price)
        });
        return total;
    }
    
    const sumOperationsNominal = function(data){
        let total = 0
        //Pesos
        data.pesos.forEach( function(item, key) {   
            total = total + parseFloat(item.qty)
        });
        //MEP
        data.mep.forEach( function(item, key) {
            total = total + parseFloat(item.qty)
        });
        //CABLE
        data.cable.forEach( function(item, key) {
            total = total + parseFloat(item.qty)
        });
        setNominal( total)
    }

    const doViewNominalOperations = function(){
        setModalNominalOpen(true)
    }

    const doSearch = async (searchDate) => {
        setsearchDate(searchDate)
        doLoad(searchDate)
    }

    if(loading){
        return( <Loading></Loading> )
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            
            <div className="row">
                <div className="col-sm-10">
                    <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">Mesa /</span> Dashboard Reporte</h4>
                </div>
                <div className="col-sm-2">
                    <label htmlFor="searchDateFrom" className="form-label">Fecha</label>
                    <input className="form-control" type="date" id="searchDateFrom" 
                        value={searchDate}
                        onChange={(e)=> doSearch(e.target.value)}
                    />
                </div>
                
                <div className="row mt-3">
                    <div className="col-sm-6">
                        <Card variant="solid" color="primary" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={20}>
                                    <SvgIcon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                        />
                                        </svg>
                                    </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                    <Typography level="body-md">Neto en Pesos</Typography>
                                    <Typography level="h2"> 
                                        <NumericFormat 
                                            displayType="text" 
                                            value={totalPesos}
                                            decimalScale={4} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                            prefix={'$'}  
                                        /> 
                                    </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('P')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                    <div className="col-sm-6">
                        <Card variant="solid" color="success" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={20}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                <Typography level="body-md">Neto en Dolar MEP</Typography>
                                <Typography level="h2">
                                    <NumericFormat 
                                        displayType="text" 
                                        value={totalMep}
                                        decimalScale={4} 
                                        allowLeadingZeros thousandSeparator="." 
                                        decimalSeparator="," 
                                        prefix={'$'}  
                                    /> 
                                </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('DM')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col-sm-6">
                        <Card variant="solid" color="warning" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={40}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                <Typography level="body-md">Neto en Dolar Cable</Typography>
                                <Typography level="h2">
                                    <NumericFormat 
                                        displayType="text" 
                                        value={totalCable}
                                        decimalScale={4} 
                                        allowLeadingZeros thousandSeparator="." 
                                        decimalSeparator="," 
                                        prefix={'$'}  
                                    /> 
                                </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('DC')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                    <div className="col-sm-6">
                        <Card variant="solid" color="danger" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={40}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                    <Typography level="body-md">Nominal</Typography>
                                    <Typography level="h2">
                                        <NumericFormat 
                                            displayType="text" 
                                            value={nominal}
                                            decimalScale={3} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                        /> 
                                    </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewNominalOperations()}}>Ver Nominales</Button>    
                            </CardActions>
                        </Card>
                    </div>
                </div>


            </div>

            <ModalDashboardOperations 
                handleOpen={setModalOperationOpen} 
                isOpen={modalOperationOpen} 
                listOperations={listModalOperations}
            />
            
            <ModalDashboardNominals 
                handleOpen={setModalNominalOpen} 
                isOpen={modalNominalOpen} 
                listOperations={listEscoOperations.nominals}
            />

        </div>   
    );

}

export default MesaDashboardReport;